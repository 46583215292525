.footer{
   padding-top: 100px;
   padding-bottom: 10px;

   @include desktop{
    padding-bottom: 50px;
   }

   .content-wrapper{
    display: flex;
    gap: 80px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 60px;
   }

   .logo{
    color: $black;
   }
   .descr{
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
   }

   .list{
    flex-direction: column;
    color: $black;
   }

    .link {
        color: $black;
        padding: 0 0 5px 0;
        margin-bottom: 5px;
    }

   .link.link-btn{
    padding: 15px 42px;
    color: $white;
   }

   .link-wrapper{
    display: flex;
    gap: 10px;
    img{
        width: 20px;
    }
   }


   .rights{
    padding: 10px 0;
    border-top: 1px solid #A7A7A7;
    @include tablet{
        
        text-align: center;
    }
   }

   .list.hidden{
    display: none;
    @include desktop{
        display: initial;
    }
   }


}
    

