.form{
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 100%;
    background-color: $white;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 16px;
    border-radius: 30px;
    max-width: 500px;
    margin: 0 auto;

    &-title{
        font-size: 22px;
        font-weight: 400;
        color: $accent;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 32px;
    }


    &-label{
        width: 100%;
    }

    &-input{
        width: 100%;
        padding: 9px;
        background-color: transparent;
        border: 2px solid #7d7d7d;
        font-size: 14px;
        line-height: 1.14;
        font-weight: 400;
        text-align: center;
        min-width: 105px;
        border-radius: 100px;
        transition: $transition;

        &:hover,&focus{
            border: 2px solid $accent;
        }

        &::placeholder{
            color: $black;
            opacity: 0.6;
            font-size: 14px;
            line-height: 1.14;
            font-weight: 400;
        }
        &.textarea{
            resize: none;
            border-radius: 20px;
        }
    }




}