.header{
    position: absolute;
    top: 0;
    left: auto;
    padding: 32px 0;
    width: 100%;
    z-index: 1;

    .navigation{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    &.priv{
        background-color: #181818;
        position: relative;
    }

}



