// =========================== privacy & terms start 
.privacy{
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: $accent;
        color: $white;
        font-family: "Inter", sans-serif;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    .content-wrapper{
        margin: 0 auto;
        max-width: 536px;
    }

    .title{
        font-size: 40px;
        line-height: 1.4;

        @include desktop{
            font-size: 56px;
        }
    }
    .descr{
        font-size: 24px;
        line-height: 1.5; 
        font-weight: 700;  
        @include desktop{
            font-size: 32px;
        }
    }

}
.content{
    padding: 100px 0 150px 0;
font-family: "Inter", sans-serif;
        .text {
                font-size: 24px;
                line-height: 1.5;
                font-weight: 700;

                margin-left: auto;
                margin-right: auto;
                max-width: 630px;
            }
        
            ul {
                padding-left: 16px;
                list-style: disc;
            }
}


// =========================== privacy & terms end 