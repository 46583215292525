$background-color_1: black;



/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */

/* fix html, body */
.hystmodal__opened {
	position: fixed;
	right: 0;
	left: 0;
	overflow: hidden;
}
/* modal backdrop */
.hystmodal__shadow {
	position: fixed;
	border: none;
	display: block;
	width: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 98;
	opacity: 0;
	transition: opacity 0.15s ease;
	background-color: $background-color_1;
}
/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
	pointer-events: auto;
	opacity: 0.6;
}
/*  backdrop що огортає враппер модалки */
.hystmodal {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 1;
	pointer-events: none;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	z-index: 99;
	visibility: hidden;
}
/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
	pointer-events: auto;
	opacity: 1;
	.hystmodal__window {
		opacity: 1;
	}
}

.hystmodal--moved {
	pointer-events: auto;
	opacity: 1;
}

.hystmodal__wrap {
	flex-shrink: 0;
	flex-grow: 0;
	width: 100%;
	min-height: 100%;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}
.hystmodal__window {
	position: relative;
	margin: 50px 0;
	box-sizing: border-box;
	flex-shrink: 0;
	flex-grow: 0;
	background: #fff;
	width: 600px;
	max-width: 100%;
	overflow: visible;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
}
.hystmodal__close {
	position: absolute;
	z-index: 10;
	top: 20px;
	right: 20px;
	display: block;
	width: 30px;
	height: 30px;
	background-color: transparent;
	// background-position: center center;
	// background-repeat: no-repeat;
	// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
	// background-size: 100% 100%;
	border: none;
	font-size: 0;
	cursor: pointer;
	outline: none;
	transition: color 300ms ease-in-out;
	svg {
		fill: currentColor;
	}
	&:hover {
		color: $accent;
	}
	&:focus {
		color: $accent;
		outline: 2px dotted #afb3b9;
		outline-offset: 2px;
	}
    &:active{
        color: inherit;
    }
}

/* ------------ pop-up */
// .hystmodal.hystmodal--cookie {
// 	.hystmodal__wrap{
// 		justify-content: flex-end;
// 	}
//     .hystmodal__window {
// 			padding: 20px;
// 			background-color: #2c2c2c;
// 			border-radius: 40px;
// 			width: 100%;
// 			max-width: 720px;
// 			margin-bottom: 30px;

// 			& .hystmodal__close{
// 				display: none;
// 			}
//     }

// 	// .modal-content{
// 	// 	padding: 30px;
// 	// 	background-color: #2c2c2c;
// 	// 	border-radius: 40px;
// 	// 	width: 100%;
// 	// 	max-width: 720px;
		
// 	// }
// }



/* ------------ burger */
.hystmodal.hystmodal--burger {
	.hystmodal__wrap {
		justify-content: flex-start;
	}
	.hystmodal__window {
		margin: 0;
		width: 100%;
		height: 100%;
        color: black;
		padding-top: 41px;
		padding-bottom: 30px;

		.container{
			height: 100%;
		}
	}

	.navigation.burger{
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 30px;

		.logo {
				color: $black;
			}

		.header-nav{
			gap: 30px;
			width: 100%;
			height: 100%;
			padding-top: 40px;
			.list-link__item:last-child{
				margin-top: auto;
			}


			.link {
				color: $black;
			}
			.link.link-btn{
				color: $white;
			}

			
		}
	}
	& address{
		margin-top: 200px;
	}
		& .header-address{
			align-items: flex-start;
			flex-direction: column;
		}
		& a.header-address-link {
			color: $black;
			max-width: 100%;
		}
				
}