// font family
$main-font: "Montserrat", sans-serif;

// bg
$main-bg:#FFFFFF;
// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #fff;
$black:#111111;
$accent:#154196;
$second:#5a91ff;
$error:#e45f00;

// modal
$modal-bgd: #2C2C2C;


