@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 390px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1360px) {
  .container {
    padding: 0 30px;
    max-width: 1360px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  background-color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #111111;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

section {
  padding: 60px 0;
}

.logo {
  font-size: 25px;
  line-height: 35px;
  font-weight: 900;
  color: #fff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
  max-width: max-content;
  display: flex;
  gap: 12px;
}
.logo:hover, .logo:focus {
  color: #154196;
}
.logo:active {
  color: #5a91ff;
}
.logo-footer {
  color: #111111;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 16px 12px 15px 12px;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}
.link:hover, .link:focus {
  color: #154196;
  border-bottom: 1px solid #154196;
}
.link:active {
  color: #5a91ff;
  border-bottom: 1px solid transparent;
}

.link-btn {
  font-weight: 600;
  min-width: 178px;
  width: max-content;
  padding: 15px 42px;
  background-color: #154196;
  border-radius: 100px;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.link-btn:hover, .link-btn:focus {
  background-color: #5a91ff;
}
.link-btn:active {
  background-color: #154196;
}

.link-btn-second {
  font-weight: 600;
  width: max-content;
  padding: 15px 32px;
  background-color: transparent;
  border-radius: 100px;
  font-size: 16px;
  line-height: 26px;
  color: #154196;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 1px solid #154196;
}
.link-btn-second:hover, .link-btn-second:focus {
  background-color: #5a91ff;
}
.link-btn-second:active {
  background-color: transparent;
}

.btn {
  width: 100%;
  padding: 15px 42px;
  background-color: #154196;
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #154196;
  cursor: pointer;
  border-radius: 100px;
}
.btn.hero-btn {
  max-width: max-content;
}

.btn-burger {
  margin: 0;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0);
}
.hfa:hover, .hfa:focus {
  border: 1px solid #154196;
  background-color: #5a91ff;
}
.hfa:active {
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}

.supheader {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.heading1 {
  font-size: 48px;
  line-height: 1.2;
}
@media screen and (min-width: 1360px) {
  .heading1 {
    font-size: 72px;
    line-height: 82px;
  }
}

.heading2 {
  font-size: 40px;
  line-height: 50px;
}

.heading3 {
  font-size: 20px;
  line-height: 30px;
}

.heading4 {
  font-size: 16px;
  line-height: 26px;
}

.m-0 {
  margin: 0;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.just-validate-error-field.form-input {
  border: 2px solid #e45f00;
}

.just-validate-error-label {
  display: none;
}

.header-nav.mobile-hidden, .upper.mobile-hidden, .list.mobile-hidden, img.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .header-nav.mobile-hidden, .upper.mobile-hidden, .list.mobile-hidden, img.mobile-hidden {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, .upper.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

@media screen and (min-width: 1360px) {
  .header-nav.tablet-hidden, .upper.tablet-hidden {
    display: flex;
  }
}

@media screen and (min-width: 1360px) {
  img.desktop-hidden, .btn-burger.desktop-hidden {
    display: none;
  }
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.submit {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.submit main {
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: auto;
  padding: 32px 0;
  width: 100%;
  z-index: 1;
}
.header .navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.header.priv {
  background-color: #181818;
  position: relative;
}

.footer {
  padding-top: 100px;
  padding-bottom: 10px;
}
@media screen and (min-width: 1360px) {
  .footer {
    padding-bottom: 50px;
  }
}
.footer .content-wrapper {
  display: flex;
  gap: 80px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
}
.footer .logo {
  color: #111111;
}
.footer .descr {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}
.footer .list {
  flex-direction: column;
  color: #111111;
}
.footer .link {
  color: #111111;
  padding: 0 0 5px 0;
  margin-bottom: 5px;
}
.footer .link.link-btn {
  padding: 15px 42px;
  color: #fff;
}
.footer .link-wrapper {
  display: flex;
  gap: 10px;
}
.footer .link-wrapper img {
  width: 20px;
}
.footer .rights {
  padding: 10px 0;
  border-top: 1px solid #A7A7A7;
}
@media screen and (min-width: 768px) {
  .footer .rights {
    text-align: center;
  }
}
.footer .list.hidden {
  display: none;
}
@media screen and (min-width: 1360px) {
  .footer .list.hidden {
    display: initial;
  }
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: #154196;
}
.hystmodal__close:focus {
  color: #154196;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: black;
  padding-top: 41px;
  padding-bottom: 30px;
}
.hystmodal.hystmodal--burger .hystmodal__window .container {
  height: 100%;
}
.hystmodal.hystmodal--burger .navigation.burger {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.hystmodal.hystmodal--burger .navigation.burger .logo {
  color: #111111;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav {
  gap: 30px;
  width: 100%;
  height: 100%;
  padding-top: 40px;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav .list-link__item:last-child {
  margin-top: auto;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav .link {
  color: #111111;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav .link.link-btn {
  color: #fff;
}
.hystmodal.hystmodal--burger address {
  margin-top: 200px;
}
.hystmodal.hystmodal--burger .header-address {
  align-items: flex-start;
  flex-direction: column;
}
.hystmodal.hystmodal--burger a.header-address-link {
  color: #111111;
  max-width: 100%;
}

.hero {
  padding: 0;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/1.webp");
  color: #fff;
  -webkit-box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);
  box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);
}
.hero .container {
  height: 100%;
  position: relative;
}
.hero .container .link-btn {
  padding: 15px 60px;
}
.hero .container .left-links {
  position: absolute;
  right: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.hero .container .left-links::before {
  content: "";
  position: absolute;
  top: 28vh;
  left: 50%;
  width: 2px;
  height: 16vh;
  background-color: #5a91ff;
}
.hero .container .left-links::after {
  content: "";
  position: absolute;
  bottom: 28vh;
  left: 50%;
  width: 2px;
  height: 16vh;
  background-color: #5a91ff;
}
.hero .container .left-links.hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .hero .container .left-links.hidden {
    display: flex;
  }
}
.hero .content-wrapper {
  width: 100%;
  max-width: 556px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  gap: 20px;
}

.about {
  padding: 100px 0;
}
.about .heading2 {
  max-width: 900px;
  margin-right: auto;
}
.about .descr {
  max-width: 204px;
  margin-right: auto;
}
@media screen and (min-width: 1360px) {
  .about .descr {
    max-width: 900px;
  }
}
.about .list__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 100px;
}
@media screen and (min-width: 768px) {
  .about .list__item {
    flex-direction: row-reverse;
    align-items: center;
    gap: 30px;
  }
  .about .list__item:nth-child(2) {
    flex-direction: row;
  }
}
@media screen and (min-width: 1360px) {
  .about .list__item {
    gap: 50px;
  }
}
.about .link-wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.about img {
  max-width: 351px;
}
@media screen and (min-width: 1360px) {
  .about img {
    max-width: 625px;
  }
}

.services {
  padding: 80px 0;
}
@media screen and (min-width: 1360px) {
  .services {
    padding-top: 0;
    padding-bottom: 100px;
  }
}
.services .content-wrapper {
  padding: 30px 0;
  border-top: 1px solid #A7A7A7;
  border-bottom: 1px solid #A7A7A7;
}
.services .list__item {
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}
@media screen and (min-width: 768px) {
  .services .list__item {
    padding: 20px;
    flex-direction: row;
    align-items: center;
    gap: 100px;
  }
}
@media screen and (min-width: 1360px) {
  .services .list__item {
    padding: 50px;
  }
}
.services .list__item:nth-child(2) {
  color: #fff;
  background-color: #154196;
}
.services .list__item:nth-child(2) .link-btn {
  background-color: #fff;
  color: #111111;
}
.services .list__item:nth-child(2) .link-btn:hover, .services .list__item:nth-child(2) .link-btn:focus {
  background-color: #5a91ff;
}
.services .list__item:nth-child(2) .link-btn:active {
  background-color: #fff;
}
.services .left {
  max-width: 300px;
}
.services .right {
  max-width: 300px;
}
@media screen and (min-width: 1360px) {
  .services .right {
    max-width: 500px;
  }
}

.projects {
  padding-top: 0;
  padding-bottom: 100px;
}
@media screen and (min-width: 1360px) {
  .projects {
    padding-bottom: 60px;
  }
}
.projects .text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 150px;
  margin-bottom: 50px;
}
.projects .desktop-descr {
  display: none;
}
@media screen and (min-width: 1360px) {
  .projects .desktop-descr {
    display: block;
    align-self: flex-end;
    max-width: 500px;
  }
}
.projects .title-wrapper {
  max-width: 300px;
}
.projects .list-upper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 80px;
}
@media screen and (min-width: 1360px) {
  .projects .list-upper {
    margin-bottom: 50px;
  }
}
.projects .list-upper__item {
  position: relative;
  width: 33%;
  height: 400px;
  overflow: hidden;
}
.projects .list-upper__item img {
  width: 430px;
  max-width: 430px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.projects .list-lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 1360px) {
  .projects .list-lower {
    flex-direction: row;
    justify-content: center;
  }
}
.projects .list-lower__item {
  position: relative;
  padding: 50px 30px;
  width: 270px;
  height: 425px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .projects .list-lower__item {
    width: 630px;
    height: 400px;
  }
}
@media screen and (min-width: 1360px) {
  .projects .list-lower__item {
    width: 48%;
  }
}
.projects .list-lower__item img {
  width: 630px;
  max-width: 630px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.projects .list-lower__item h3, .projects .list-lower__item p {
  position: relative;
  color: #fff;
}

.reviews {
  padding-top: 0;
  padding-bottom: 100px;
}
@media screen and (min-width: 1360px) {
  .reviews {
    padding-bottom: 80px;
  }
}
.reviews .text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 150px;
  margin-bottom: 80px;
}
.reviews .desktop-descr {
  display: none;
}
@media screen and (min-width: 1360px) {
  .reviews .desktop-descr {
    display: block;
    max-width: 500px;
    align-self: flex-end;
  }
}
.reviews .title-wrapper {
  max-width: 500px;
}
.reviews .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
@media screen and (min-width: 1360px) {
  .reviews .list {
    flex-direction: row;
  }
}
.reviews .list__item {
  min-height: 359px;
  padding: 30px;
  background-color: rgba(127, 127, 127, 0.2);
  border-radius: 8px;
}
@media screen and (min-width: 768px) {
  .reviews .list__item {
    max-width: 500px;
    margin: auto;
  }
}
@media screen and (min-width: 1360px) {
  .reviews .list__item {
    max-width: 33%;
  }
}
.reviews .list__item p {
  margin-top: 20px;
  margin-bottom: 30px;
}
.reviews .list .quote {
  width: 80px;
  height: 84px;
}
.reviews .list .author {
  border-top: 1px solid #fff;
  padding-top: 30px;
  display: flex;
  gap: 10px;
}
.reviews .list .author img {
  width: 50px;
  height: 50px;
}

.navigate {
  padding: 80px 0;
  background-color: #154196;
  color: #fff;
}
.navigate .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 730px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .navigate .content-wrapper {
    text-align: center;
  }
}
.navigate .link-btn {
  background-color: #fff;
  color: #111111;
  min-width: 270px;
}
.navigate .link-btn:hover, .navigate .link-btn:focus {
  background-color: #5a91ff;
}
.navigate .link-btn:active {
  background-color: #fff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  border-radius: 30px;
  max-width: 500px;
  margin: 0 auto;
}
.form-title {
  font-size: 22px;
  font-weight: 400;
  color: #154196;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;
}
.form-label {
  width: 100%;
}
.form-input {
  width: 100%;
  padding: 9px;
  background-color: transparent;
  border: 2px solid #7d7d7d;
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
  text-align: center;
  min-width: 105px;
  border-radius: 100px;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.form-input:hover, .form-inputfocus {
  border: 2px solid #154196;
}
.form-input::placeholder {
  color: #111111;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.14;
  font-weight: 400;
}
.form-input.textarea {
  resize: none;
  border-radius: 20px;
}

.privacy {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #154196;
  color: #fff;
  font-family: "Inter", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.privacy .content-wrapper {
  margin: 0 auto;
  max-width: 536px;
}
.privacy .title {
  font-size: 40px;
  line-height: 1.4;
}
@media screen and (min-width: 1360px) {
  .privacy .title {
    font-size: 56px;
  }
}
.privacy .descr {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (min-width: 1360px) {
  .privacy .descr {
    font-size: 32px;
  }
}

.content {
  padding: 100px 0 150px 0;
  font-family: "Inter", sans-serif;
}
.content .text {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  max-width: 630px;
}
.content ul {
  padding-left: 16px;
  list-style: disc;
}