// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}

section{
  padding: 60px 0;

}


// ------------------------------  logo 
.logo{

  font-size: 25px;
  line-height: 35px;
  font-weight: 900;
  color: $white;
  transition: $transition;
  border-bottom: 1px solid transparent;
  max-width: max-content;

  display: flex;
  gap: 12px;

  &:hover, &:focus {
      color: $accent;
    }
  
  &:active {
    color: $second;
  }

  &-footer{
    color: $black;
  }
}


// ------------------------------ links 

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 16px 12px 15px 12px;

  font-size: 16px;
  line-height: 26px;
  color: $white;

  transition: $transition;
  border-bottom: 1px solid transparent;
  
  &:hover,&:focus{
    color: $accent;
    border-bottom: 1px solid $accent;
  }
  &:active{
    color: $second;
    border-bottom: 1px solid transparent;
  }
}

.link-btn {
  font-weight: 600;
  min-width: 178px;
  width: max-content;
  padding: 15px 42px;
  background-color: $accent;
  border-radius: 100px;

    font-size: 16px;
      line-height: 26px;
      color: $white;

        transition: $transition;
  &:hover,
  &:focus {
    background-color: $second;
  }

  &:active {
    background-color: $accent;
  }

}
.link-btn-second {
  font-weight: 600;
  width: max-content;
  padding: 15px 32px;
  background-color: transparent;
  border-radius: 100px;

  font-size: 16px;
  line-height: 26px;
  color: $accent;

  transition: $transition;
  border: 1px solid $accent;

  &:hover,
  &:focus {
    background-color: $second;
  }

  &:active {
    background-color: transparent;
  }

}




// --------------------------------- btn
.btn {
  width: 100%;
  padding: 15px 42px;
  background-color: $accent;
  color: $white;
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
  border: 1px solid $accent;
  cursor: pointer;
  border-radius: 100px;

  &.hero-btn{
    max-width: max-content;
  }
}

.btn-burger{
  margin: 0;
  border: none;
  padding: 0;
  // color: $black;
  background-color: transparent;
  // transition: $transition;
  cursor: pointer;
  // &:hover,&focus{
  //   color: $accent;
  // }
  // &:active{
  //   color: $second;
  // }
  // svg{
  //   stroke: currentColor;
  // }
}

// ------------------------------ btn hover focus active 
.hfa {
  transition: $transition;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.0);

  &:hover,
  &:focus {
    border: 1px solid $accent;
    background-color: $second;
    
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  }
}

.supheader{
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;

}

// ------------------------------ headings 1  2  3

.heading1{
  font-size: 48px;
  line-height: 1.2;
  @include desktop{
    font-size: 72px;
    line-height: 82px;

  }

}
.heading2 {
    font-size: 40px;
    line-height: 50px;
}
.heading3 {
  font-size: 20px;
  line-height: 30px;
}

.heading4 {
  font-size: 16px;
  line-height: 26px;

}

// ------------------------------ headings 1  2  3

// .list{
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   @include tablet {
//     flex-direction: row;
//     flex-wrap: wrap;
//   }
// }

// .list__item{
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
//   padding: 16px;
//   border-top: 1px solid $accent;
//   width: 100%;
//   background-color: $white;

//   @include tablet {
//     max-width: 344px;
//   }
//   @include desktop{
//     max-width: 232px;
//   }
  
// }


//margin

.m-0{
  margin: 0;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:2px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden 
.header-nav,.upper, .list, img {
  &.mobile-hidden{
    display: none;
    @include tablet{
      display: flex;
    }
  }
}
br, .header-nav,.upper, img{
  &.tablet-hidden {
    @include tablet{
      display: none;
    }
  }
}
.header-nav.tablet-hidden,.upper.tablet-hidden {
  @include desktop {
    display: flex;
  }
}
img, .btn-burger{
  &.desktop-hidden {

      @include desktop {
        display: none;
      }
    }
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.submit {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & main {
    align-self: center;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
  }


}


// ========================================= common end


