// ========================================= container start 
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @include mobile {
    max-width: 390px;
  }

  @include tablet {
    max-width: 768px;
  }

  @include desktop {
    padding: 0 30px;
    max-width: 1360px;
  }
}

// ========================================= container end 