.hero{
    padding: 0;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/1.webp');

    color: $white;

    -webkit-box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);
        box-shadow: inset 139px -1px 116px -55px rgba(0, 0, 0, 0.7);

    .container{
        height: 100%;
position: relative;
        .link-btn {
                padding: 15px 60px;
            }
        
            .left-links {
                position: absolute;
                right: 50px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;

                &::before{
                    content: '';
                    position: absolute;
                    top: 28vh;
                    left: 50%;
                    width: 2px;
                    height: 16vh;
                    background-color: $second;
                }
                &::after     {
                    content: '';
                    position: absolute;
                    bottom: 28vh;
                    left: 50%;
                    width: 2px;
                    height: 16vh;
                    background-color: $second;
                }

                &.hidden{
                    display: none;
                    @include tablet{
                        display: flex;
                    }
                }
            }


    }

    .content-wrapper{
        
        width: 100%;
        max-width: 556px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: flex-start;
        gap: 20px;

    }

    

}

.about{
    padding:100px 0;

    .heading2{
        max-width: 900px;
        margin-right: auto;
    }
    .descr{
        max-width: 204px;
        margin-right: auto;
        @include desktop{
            max-width: 900px;
        }
    }

    

    .list__item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
        margin-bottom: 100px;


        @include tablet{
            flex-direction: row-reverse;
            align-items: center;
            gap: 30px;
            &:nth-child(2) {
                    flex-direction: row;
                }
        }
        @include desktop{
            gap: 50px;
        }
    }

    .link-wrapper{
        display: flex;
        gap: 20px;
        align-items: flex-start;
    }

    img{
        max-width: 351px;
        @include desktop{
            max-width: 625px;
        }
    }

}

.services{
    padding: 80px 0;
    // background-color: rgba(127, 127, 127, 0.1);

    @include desktop{
        padding-top: 0;
        padding-bottom: 100px;
    }

    .content-wrapper{
        padding: 30px 0;
        border-top: 1px solid #A7A7A7;
        border-bottom: 1px solid #A7A7A7;
    }

    .list__item{

        padding: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;

        @include tablet{
            padding: 20px;
            flex-direction: row;
            align-items: center;
            gap: 100px;
        }

        @include desktop{
            padding: 50px;
        }

        &:nth-child(2){
            color: $white;
            background-color: $accent;
            .link-btn{
                background-color: $white;
                color: $black;

                &:hover,&:focus{
                    background-color: $second;
                }
                &:active{
                    background-color: $white;
                }
            }
        }
    }

    .left{
        max-width: 300px;
    }
    .right{
        max-width: 300px;
        @include desktop{
        max-width: 500px;
        }

    }


}

.projects{
    padding-top: 0;
    padding-bottom: 100px;
    @include desktop{
        padding-bottom: 60px;
    }

    .text-wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap:150px;
        margin-bottom: 50px;
    }
    .desktop-descr{
        display: none;
        @include desktop{
            display: block;
            align-self: flex-end;
            max-width: 500px;
        }
    }

    .title-wrapper{
        max-width: 300px;
    }


    .list-upper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 80px;
        @include desktop{
            margin-bottom: 50px;
        }
       

        &__item{
            position: relative;
            width: 33%;
            height: 400px;
            overflow: hidden;
            img{
                width: 430px;
                max-width: 430px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            
        }
    }
    .list-lower{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @include desktop {
            flex-direction: row;
            justify-content: center;
        }

        &__item{
            position: relative;
            padding: 50px 30px;
            width: 270px;
            height: 425px;
            overflow: hidden;

            @include tablet{
                width: 630px;
                height: 400px;
            }

            @include desktop{
                width: 48%;
                
            }

            img {
                width: 630px;
                max-width: 630px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
            }

            & h3,p{
                position: relative;
                color: $white;
            }

        }
    }

}

.reviews{
    padding-top: 0;
    padding-bottom: 100px;
    @include desktop{
        padding-bottom: 80px;
    }

    .text-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 150px;
        margin-bottom: 80px;
    }

    .desktop-descr {
        display: none;

        @include desktop {
            display: block; 
            max-width: 500px;
            align-self: flex-end;
        }
    }

    .title-wrapper {
        max-width: 500px;
    }

    .list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;

        @include desktop{
            flex-direction: row;
        }

        &__item{
            min-height: 359px;
            padding: 30px;
            background-color: rgba(127, 127, 127, 0.2);
            border-radius: 8px;

            @include tablet{
                max-width: 500px;
                margin: auto;
            }
            @include desktop{
                max-width: 33%;
            }

            p{
                margin-top: 20px;
                margin-bottom: 30px;
            }
            
        }

        .quote{
            width: 80px;
            height: 84px;
        }
        .author{
            border-top: 1px solid $white;
            padding-top: 30px;
            display: flex;
            gap: 10px;

            img{
                width: 50px;
                height: 50px;
            }
        }
    }


}

.navigate{
    padding: 80px 0;


    background-color: $accent;
    color: $white;

    .content-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        max-width: 730px;
        margin: 0 auto;

        @include tablet{
            text-align: center;
        }
    }


    .link-btn {
            background-color: $white;
            color: $black;
            min-width: 270px;
    
            &:hover,
            &:focus {
                background-color: $second;
            }
    
            &:active {
                background-color: $white;
            }
        }
}